import { experiment } from './experiment';
import { useState, useEffect } from 'react';

/**
 * Used for getting the users experiment variant
 * @param {string} experimentName - The experiment name we want to see the users variant for.
 */
export const useExperiment = (experimentName) => {
  const variant = experiment.variant(experimentName);

  const [isLoading, setIsLoading] = useState(experiment.isLoading);
  const [value, setValue] = useState(variant?.value);
  const [payload, setPayload] = useState(variant?.payload);

  useEffect(() => {
    // This value will be false when the component is unmounted
    let isSubscribed = true;

    if (experiment.isLoading) {
      const waitUntilFetched = async () => {
        await experiment._fetchFn;
        if (isSubscribed) {
          const variant = experiment.variant(experimentName);
          setIsLoading(experiment.isLoading);
          setValue(variant?.value);
          setPayload(variant?.payload);
        }
      };

      waitUntilFetched();
    }

    // This cleanup function is invoked before the component is unmounted
    return () => (isSubscribed = false);
  }, []);

  return [isLoading, value, payload];
};
