import React from 'react';

import { useTracking } from './useTracking';

export const withTracking = (WrappedComponent, eventData) => {
  const WithTracking = ({ forwardedRef, ...props }) => {
    const { trackEvent, Track } = useTracking(eventData);
    return (
      <Track>
        <WrappedComponent
          ref={forwardedRef}
          trackEvent={trackEvent}
          {...props}
        />
      </Track>
    );
  };

  return React.forwardRef((props, ref) => {
    return <WithTracking forwardedRef={ref} {...props} />;
  });
};
