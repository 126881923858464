import { experiment } from './experiment';

/**
 * Used for getting the multiple experiment variants
 * @param {array} experiments - The experiment names we want to see the users variant for.
 */
export const useMultipleExperiments = (experiments = []) => {
  const experimentResults = experiments.reduce((acc, experimentName) => {
    return {
      ...acc,
      [experimentName]: experiment.variant(experimentName)
    };
  }, {});

  return experiment.isLoading ? [true, {}] : [false, experimentResults];
};
