/* eslint-disable no-unused-expressions */
import { Experiment } from '@amplitude/experiment-js-client';

const isProd = () => process.env.NODE_ENV === 'production';
const defaultEnabled =
  typeof navigator === 'undefined'
    ? true
    : isProd() && navigator?.userAgent !== 'qa-automation-bot';

export class ExperimentClient {
  get _instance() {
    return this._enabled ? this._client : null;
  }

  /**
   * Fetching a users experiment uses a promise to resolve meaning we need to make sure we
   * keep track of whether the users experiment variant has been returned
   */
  get isLoading() {
    return this._enabled ? this._loading : false;
  }

  /**
   * Initialize the experiment SDK. See https://developers.experiment.amplitude.com/docs/javascript-client-sdk
   * @param {boolean} enabled - Wether or not to enable experiments.
   * @param {debug} debug - Show debug logs.
   * @param string environmentKey - The environment key for amplitude experiments.
   * @param {boolean} connectToAnalytics should connect to analytics SDK.
   * @param {object} experimentUser - The user to fetch experiment variants for
   */
  init({ connectToAnalytics, debug, enabled, environmentKey, experimentUser }) {
    if (this._instance) return;

    this._loading = true;

    this._enabled = enabled || defaultEnabled;
    this._debug = debug || false;

    const experimentConfig = { debug: this._debug };

    this._client = connectToAnalytics
      ? Experiment.initializeWithAmplitudeAnalytics(
          environmentKey,
          experimentConfig
        )
      : Experiment.initialize(environmentKey, experimentConfig);

    this._fetchFn = this._instance?.fetch(experimentUser).then(() => {
      this._loading = false;
    });
  }

  /**
   * Gets the user's variant for a specific experiment
   * @param {string} experimentName - The name of the experiment to fetch variants for. Usually dash case `experiment-name`.
   */
  variant(experimentName) {
    return this._instance?.variant(experimentName);
  }

  /**
   * Fetch after setting user properties for real-time targeting.
   * Another usage is if you want to use user properties to target a user but you
   * a) don’t want them to be written to the user’s Analytics profile or
   * b) the user property doesn’t exist in Analytics,
   * you can include the properties as a JSON in the fetch request.
   * @param {object} properties - Properties to set
   */
  fetch(properties) {
    return this._instance?.fetch(properties);
  }
}
